import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Switch } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import _ from "lodash";

import { searchClientItems, searchItems } from "../../redux/actions";
import Clients from "../Clients";

const { TextArea } = Input;
const { Option } = Select;

export default ({ defaultValues = {}, form }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  //  Local State
  const [client, setClient] = useState(defaultValues.client);
  const [loadingItems, setLoadingItems] = useState(false);
  const [loadingClientItems, setLoadingClientItems] = useState(false);
  const [clientsModalVisible, setClientsModalVisible] = useState(false);
  const [clientItems, setClientItems] = useState([]);

  // Redux State
  const items = useSelector((state) => state.items.data, shallowEqual);
  const itemsArray = Object.values(items);
  const clientItemsArray = Object.values(clientItems);

  useEffect(() => {
    setLoadingItems(true);
    dispatch(searchItems(1, 999999, { disabled: { $ne: true } }))
      .then(({ payload }) => setClientItems(payload.data))
      .finally(() => setLoadingItems(false));
    if (client) {
      setLoadingClientItems(true);
      dispatch(searchClientItems(1, -1, { client: client._id })).finally(() =>
        setLoadingClientItems(false)
      );
    }
  }, []);

  useEffect(() => {
    if (client) {
      setLoadingClientItems(true);
      dispatch(searchClientItems(1, -1, { client: client._id }))
        .then(({ payload }) => setClientItems(payload.data))
        .finally(() => setLoadingClientItems(false));
    }
  }, [client]);

  const requiredRule = {
    required: true,
    message: intl.formatMessage({ id: "fieldRequired" }),
  };

  const renderItems = () => {
    return itemsArray.filter((i) => i?.disabled !== true).map((i) => (
      <Option key={i._id} value={i._id}>
        {i.title}
      </Option>
    ));
  };

  const renderClientItems = () => {
    return clientItems.map((c) => (
      <Option key={c._id} value={c._id}>
        #{c._ref} {c._previousRef && `(${c._previousRef})`} {c.title}
      </Option>
    ));
  };

  const onClientSelection = (value, row) => {
    setClient(row[0]);
    form.setFieldsValue({ client: row[0]._id });
    const formValue = form.getFieldsValue();
    const items = formValue.items.map((i) => ({
        ...i,
        clientItem: undefined,
      }));
    form.setFieldsValue({ items });
    setClientsModalVisible(false);
  };

  const renderClientName = () => {
    if (!client) return intl.formatMessage({ id: "selectClient" });
    return `${_.get(client, 'firstName', '')} ${_.get(client, 'lastName', '')} [${_.get(client, 'companyName', '')}]`;
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.items && changedValues.items.some(item => item.unitCost || item.qty)) {
      form.setFieldsValue({ ...allValues, items: allValues.items.map(item => ({ ...item, total: item.unitCost * item.qty })) });
    }
  };

  useEffect(() => {
    onValuesChange(form.getFieldsValue(), form.getFieldsValue());
  }, []);

  const updateUnitCost = (change, index) => {
    const items = form.getFieldValue("items");
    const selectedItem = itemsArray.find(i => i._id === change);
    form.setFieldsValue({ "items": items.map((item, i) => i === index ? {...item, unitCost: _.get(selectedItem, 'price', 0)} : item) });
    onValuesChange(form.getFieldsValue(), form.getFieldsValue());
  };

  const updateClientItemCost = (change, index) => {
    const items = form.getFieldValue("items");
    const selectedItem = clientItemsArray.find((i) => i._id === change);
    form.setFieldsValue({ "items": items.map((item, i) => i === index ? {...item, unitCost: _.get(selectedItem, 'price', 0)} : item) });
    onValuesChange(form.getFieldsValue(), form.getFieldsValue());
  };

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onValuesChange={onValuesChange}
        initialValues={defaultValues}
      >
        <Row gutter="16">
          <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
            <Form.Item label={intl.formatMessage({ id: "client" })} name="client" rules={[requiredRule]}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Input value={renderClientName()} disabled />
                <Button
                  type="primary"
                  onClick={() => setClientsModalVisible(true)}
                >
                  {intl.formatMessage({ id: "select" })}
                </Button>
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={6} xxl={6}>
            <Form.Item
              name="startDate"
              label={intl.formatMessage({ id: "startDate" })}
            >
              <DatePicker allowClear style={{ width: "100%" }} format={'DD/MM/YYYY'} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={6} xxl={6}>
            <Form.Item
              name="dueDate"
              label={intl.formatMessage({ id: "dueDate" })}
            >
              <DatePicker allowClear style={{ width: "100%" }} format={'DD/MM/YYYY'} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            <Form.Item
              name="subscription"
              label={intl.formatMessage({ id: "subscription" })}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              name="instructions"
              label={intl.formatMessage({ id: "instructions" })}
            >
              <TextArea autoSize />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.List name="items">
              {(fields, { add, remove }) => {
                return (
                  <Card
                    title={intl.formatMessage({ id: "productsServices" })}
                    extra={
                      <Button
                        type="dashed"
                        onClick={() => {
                          add({ qty: 1, unitCost: 0, total: 0 });
                        }}
                      >
                        <PlusOutlined /> {intl.formatMessage({ id: "add" })}
                      </Button>
                    }
                    bodyStyle={{ padding: "0px 8px" }}
                    style={{ margin: "8px 0px" }}
                  >
                    {fields.map((field, index) => (
                      <Row
                        gutter="16"
                        key={`items-${index}`}
                        style={{ border: "1px dashed #d9d9d9", padding: 8 }}
                      >
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                          <Form.Item
                            {...field}
                            label={intl.formatMessage({ id: "productService" })}
                            name={[field.name, "item"]}
                            fieldKey={[field.fieldKey, "item"]}
                            rules={[requiredRule]}
                          >
                            <Select showSearch loading={loadingItems} onChange={(c) => updateUnitCost(c, index)} optionFilterProp="children">
                              {renderItems(itemsArray)}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                          <Form.Item
                            {...field}
                            label={intl.formatMessage({ id: "clientItem" })}
                            name={[field.name, "clientItem"]}
                            fieldKey={[field.fieldKey, "clientItem"]}
                          >
                            <Select allowClear showSearch loading={loadingClientItems} disabled={_.isEmpty(client)} onChange={(c) => updateClientItemCost(c, index)}>
                              {renderClientItems()}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
                          <Form.Item
                            {...field}
                            label={intl.formatMessage({ id: "qty" })}
                            name={[field.name, "qty"]}
                            fieldKey={[field.fieldKey, "qty"]}
                            rules={[requiredRule]}
                            initialValue={1}
                          >
                            <InputNumber min={1} style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
                          <Form.Item
                            {...field}
                            label={intl.formatMessage({ id: "unitCost" })}
                            name={[field.name, "unitCost"]}
                            fieldKey={[field.fieldKey, "unitCost"]}
                            rules={[requiredRule]}
                            initialValue={0}
                          >
                            <InputNumber min={0} style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
                          <Form.Item
                            name={[field.name, "total"]}
                            label={intl.formatMessage({ id: "total" })}
                            initialValue={0}
                          >
                            <InputNumber disabled={true} min={0} style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
                          <Form.Item
                            {...field}
                            label={intl.formatMessage({ id: "storage" })}
                            name={[field.name, "storage", "notes"]}
                            fieldKey={[field.fieldKey, "storage.notes"]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={18} sm={18} md={20} lg={20} xl={22} xxl={22}>
                          <Form.Item
                            {...field}
                            label={intl.formatMessage({ id: "notes" })}
                            name={[field.name, "notes"]}
                            fieldKey={[field.fieldKey, "notes"]}
                          >
                            <TextArea autoSize />
                          </Form.Item>
                        </Col>
                        {fields.length > 1 && (
                          <Col xs={6} sm={6} md={4} lg={4} xl={2} xxl={2}>
                            <Form.Item
                              label={intl.formatMessage({ id: "remove" })}
                            >
                              <Button
                                type="dashed"
                                danger
                                onClick={() => {
                                  remove(field.name);
                                }}
                              >
                                <MinusCircleOutlined />
                              </Button>
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    ))}
                  </Card>
                );
              }}
            </Form.List>
          </Col>
        </Row>
      </Form>
      <Modal
        bodyStyle={{ padding: 0 }}
        footer={null}
        onCancel={() => setClientsModalVisible(false)}
        title={intl.formatMessage({ id: "selectClient" })}
        visible={clientsModalVisible}
        width="75%"
        mask={true}
      >
        <Clients hideHeader={true} onRowSelection={onClientSelection} />
      </Modal>
    </div>
  );
};
